<template>
  <div>
    <!--     分销代理展示不同  需要判断一下当前是分销还是代理 分销比代理多一个审核节点-->
    <!--    分销 -->
    <div class="wrap" v-if="isFenxiao">
      <div class="pur-top-right-icon">
        <i
            class="iconfont icon-daishenhe"
            :class="[currentIndex >= 1 ? 'i-green' : 'i-gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        />
        <span
            :class="[currentIndex > 1 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        ></span>
        <i
            class="iconfont icon-fenpei"
            :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        />
        <span
            :class="[currentIndex > 2 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        ></span>
        <i
            class="iconfont icon-chengyunshang"
            :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        />
        <span
            :class="[currentIndex > 3 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        ></span>
        <i
            v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
            class="iconfont icon-xianchangjianding"
            :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 4 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
        ></span>
        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-querenchuku"
            :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 5 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-tuoji"
            :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 6 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-yanji"
            :class="[currentIndex >= 7 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 7 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-chayiqueren"
            :class="[currentIndex >= 8 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 8 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-tijiaoshenqing"
            :class="[currentIndex >= 9 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 9 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>

        <i
            v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
            class="iconfont icon-jieshu"
            :class="[currentIndex >=10 ? 'i-green' : 'i-gray']"
        />

        <i v-if="CANCEL" class="iconfont icon-quxiao i-gray" />

      </div>
      <div class="pur-top-right-txt" v-if="isFenxiao">
        <div v-if="!CANCEL || this.statusObj.CDBSCJL_FINISH">
          <p style="padding-right:4px">代理商审核</p>
          <p class="timeTxt" v-if="currentIndex >= 1 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[0].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.CDBSCJL_FINISH">
          <p style="padding-right:4px">分公司确认</p>
          <p class="timeTxt" v-if="currentIndex >= 2 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[0].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.ORDER_TICKET_FINISHED">
          <p style="padding-right:4px">委托单审核</p>
          <p class="timeTxt" v-if="currentIndex >= 3 ">{{ orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[1].date:'' }}</p>
        </div>
        <div v-if="(!CANCEL || this.statusObj.CARRIER_ASSIGN_FINISHED)">
          <p>承运商指派</p>
          <p class="timeTxt" v-if="currentIndex >= 4 ">{{ orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[2].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.DDWC">
          <p>调度完成</p>
          <p class="timeTxt" v-if="currentIndex >= 5 ">{{orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[3].date:''}}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.WLLH">
          <p>物流拉货到RDC仓</p>
          <p class="timeTxt" v-if="currentIndex >= 6 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.YJJGLR">
          <p>验机完成</p>
          <p class="timeTxt" v-if="currentIndex >= 7 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.THRK">
          <p>退货入库</p>
          <p class="timeTxt" v-if="currentIndex >= 8">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.THJZ">
          <p>退货记账</p>
          <p class="timeTxt" v-if="currentIndex >= 9">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.END">
          <p>结束</p>
          <p class="timeTxt" v-if="currentIndex >= 10 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[5].date:'' }}</p>
        </div>

        <div v-if="CANCEL">
          <p>取消</p>
          <p>{{ this.statusObj.CANCEL }}</p>
        </div>
      </div>
    </div>
    <div class="wrap" v-else>
      <div class="pur-top-right-icon">
        <i
            class="iconfont icon-fenpei"
            :class="[currentIndex >= 1 ? 'i-green' : 'i-gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        />
        <span
            :class="[currentIndex > 1 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        ></span>
        <i
            class="iconfont icon-chengyunshang"
            :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        />
        <span
            :class="[currentIndex > 2 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.UNCHKED"
        ></span>
        <i
            v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
            class="iconfont icon-xianchangjianding"
            :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 3 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
        ></span>
        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-querenchuku"
            :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 4 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-tuoji"
            :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 5 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-yanji"
            :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 6 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-chayiqueren"
            :class="[currentIndex >= 7 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 7 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>


        <i
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
            class="iconfont icon-tijiaoshenqing"
            :class="[currentIndex >= 8 ? 'i-green' : 'i-gray']"
        />
        <span
            :class="[currentIndex > 8 ? 'green' : 'gray']"
            v-if="!CANCEL || this.statusObj.WAITDELIVER"
        ></span>

        <i
            v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
            class="iconfont icon-jieshu"
            :class="[currentIndex >=9 ? 'i-green' : 'i-gray']"
        />

        <i v-if="CANCEL" class="iconfont icon-quxiao i-gray" />

      </div>
      <!-- 代理商-->
      <div class="pur-top-right-txt" v-if="!isFenxiao">
        <div v-if="!CANCEL || this.statusObj.CDBSCJL_FINISH">
          <p style="padding-right:4px">分公司确认</p>
          <p class="timeTxt" v-if="currentIndex >= 1 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[0].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.ORDER_TICKET_FINISHED">
          <p style="padding-right:4px">委托单审核</p>
          <p class="timeTxt" v-if="currentIndex >= 2 ">{{ orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[1].date:'' }}</p>
        </div>
        <div v-if="(!CANCEL || this.statusObj.CARRIER_ASSIGN_FINISHED)">
          <p>承运商指派</p>
          <p class="timeTxt" v-if="currentIndex >= 3 ">{{ orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[2].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.DDWC">
          <p>调度完成</p>
          <p class="timeTxt" v-if="currentIndex >= 4 ">{{orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[3].date:''}}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.WLLH">
          <p>物流拉货到RDC仓</p>
          <p class="timeTxt" v-if="currentIndex >= 5 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.YJJGLR">
          <p>验机完成</p>
          <p class="timeTxt" v-if="currentIndex >= 6 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.THRK">
          <p>退货入库</p>
          <p class="timeTxt" v-if="currentIndex >= 7">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.THJZ">
          <p>退货记账</p>
          <p class="timeTxt" v-if="currentIndex >= 8">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date:'' }}</p>
        </div>
        <div v-if="!CANCEL || this.statusObj.END">
          <p>结束</p>
          <p class="timeTxt" v-if="currentIndex >= 9 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[5].date:'' }}</p>
        </div>

        <div v-if="CANCEL">
          <p>取消</p>
          <p>{{ this.statusObj.CANCEL }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";
moment.locale("zh-cn");
export default {
  name: "Step",
  props: {
    orderdetail: {
      required: true,
      type: Object,
    }
  },
  ch: {
    // "orderdetail":{
    //   handler:function(){
    //       this.infoMessage = [];
    //       this.infoMessage = this.orderdetail.statusInfo
    //       console.log('this.statusObj = this.orderdetail', this.infoMessage);
    //   }

    // },
    "orderdetail": {
      handler: function() {

        this.statusObj = {};
        if (this.orderdetail && this.orderdetail.statusInfo) {
          this.orderdetail.statusInfo.forEach(item => {
            this.statusObj[item.status] = item.date;
          });
        }
        if (this.statusObj.CANCEL) {
          this.CANCEL = true;
        } else {
          this.CANCEL = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
    }),
    currentIndex: function() {
      let statusA = this.orderdetail.statusInfo.some(item=>item.code == 'END')
      let step = 1;
      if (this.orderdetail && this.orderdetail.statusInfo) {
        if(this.orderdetail.statusInfo.length == 1) {
          step = 1
        }
        if(this.orderdetail.statusInfo.length == 2) {
          step = 2
        }
        if(this.orderdetail.statusInfo.length == 3) {
          step = 3
        }
        if(this.orderdetail.statusInfo.length == 4) {
          step = 4
        }
        if(this.orderdetail.statusInfo.length == 5) {
          step = 5
        }
        if(this.orderdetail.statusInfo.length == 6) {
          step = 6
        }
        if(this.orderdetail.statusInfo.length == 7) {
          step = 7
        }
        if(this.orderdetail.statusInfo.length == 8) {
          step = 8
        }
        if(this.orderdetail.statusInfo.length == 9) {
          step = 9
        }
        if(this.orderdetail.statusInfo.length == 10) {
          step = 10
        }
        return step;
      } else {
        return step;
      }
    }
  },
  data() {
    return {
      statusObj: {},
      infoMessage:[],
      CANCEL: false,
      isFenxiao:false
    };
  },
  mounted(){
    // 有17451 的就是代理  否则都是分销
    console.log(this.isFenxiaoOrder)
    if(this.isFenxiaoOrder.indexOf("17451") !== -1 ) {
      this.isFenxiao = true;
    } else {
      this.isFenxiao = false;
    }
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped lang="less">
.wrap {
  width: 930px;
  height: 139px;
  background: #fff;

  .pur-top-right-icon {
    text-align: left;
    padding-left: 40px;

    .iconfont {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 21px;
      position: relative;
      top: 8px;
      text-align: center;
    }

    .i-green {
      color: #00aaa6;
      border: 1.5px solid #00aaa6;
    }

    .i-gray {
      color: #aaa;
      border: 1.5px solid #aaa;
    }

    img {
      width: 36px;
      height: 36px;
    }

    span {
      width: 45px;
      margin: 18px 6px 0;
    }

    .green {
      border-bottom: 1px dashed #00aaa6;
    }

    .gray {
      border-bottom: 1px dashed #cccccc;
    }
  }

  .pur-top-right-txt {
    margin: 12px 0 0 28px;
    // :nth-child(1){
    // }
    :nth-child(6) {
      margin-right: 0px;
    }

    div {
      float: left;
      width: 85px;
      margin: 0 0px 0 8px;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 6px;
        margin-top: 12px;
      }
      .timeTxt {
        margin-top: -2px;
      }
    }
    div:first-child {
      float: left;
      width: 65px;
      margin: 0 0px 0 0;
      margin-left: 0;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 6px;
        // margin-top: 12px;
      }
    }
  }
}
</style>
