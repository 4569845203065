import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
//获取渠道订单详情
export function getOrderDetail(params) {
  return service({
    url: "/defectiveProduct/detail.nd",
    params
  });
}

export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}