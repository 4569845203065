import ProductItem from "@/components/global/OrderDetail/ProductItem.vue";
import {getOrderDetail} from './api'
import cancelModal from "./../../channelReturns/distributorReturnList/components/cancelModal";
import Process from './../ReturnApplicationDetails/components/Process'
import Processkt from './../ReturnApplicationDetails/components/Processkt'
import ProcessBX from './../ReturnApplicationDetails/components/ProcessBX'
import processCW from './../ReturnApplicationDetails/components/ProcessCW.vue'
import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// import {cancelOrder} from '../components/util'
moment.locale("zh-cn");
import Btn from "@/components/global/Btn/Btn";
import {addCart, cancleChannelOrder} from "@/views/order/api";
import checkComputation from '../components/checkComputation'
import { updateStatus } from "@/views/hisenseReturns/defectiveReturnList/api";
export default {
  name: "purchaseOrderDetail",
  components: { ProductItem,Process,Processkt,Btn,checkComputation,ProcessBX,cancelModal,processCW},
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
       
        {
          path: "",
          name: "",
          title: "退货申请详情"
        }
      ],
      orderDetail:{
        orderHeader:{},
      },
      pageLoadFlag:false,
      bg:bg,
      visible:true,
      openType: '',
      visibleshop: false,
      salesOrderId:'',
      saleItem:{},
      visibleCancel:false
    };
  },
  computed:{
    totalProd:function (){
      let total = 0
      if(this.orderDetail && Array.isArray(this.orderDetail.salesDefective.detailList)){
        this.orderDetail.salesDefective.detailList.forEach(item=>{
          total+=item.qty 
        })
      }
      return parseInt(total||0)

    },
  
    totalLoadVolume:function (){
      let total = 0
      if(this.orderDetail&&Array.isArray(this.orderDetail.salesDefective.detailList)){
        this.orderDetail.salesDefective.detailList.forEach(item=>{
          if(item.loadVolume) {
            total+=item.qty*Math.max(item.loadVolume,0)
          }
        })
      }
      return total
    }

  },
  mounted(){
    if(this.$route.query.id){
      this.getDetail(this.$route.query.id)
    }else{
      this.getDetail('1096486157153')
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods:{
    openWindows(salesOrderId,type) {
      this.openType = type
      this.visibleshop = true
      this.salesOrderId = salesOrderId
    },
    openWindowsR(salesOrderId,type) {
      this.visibleCancel = true
      this.openType = type
      this.salesOrderId = salesOrderId
    },
    // 驳回窗口确定
    getReason(e) {
      if(!e) {
        this.$message.warning('请输入取消原因')
        return
      }
      let data = {
        id:this.salesOrderId,
        audit:'驳回',
        auditRemark:e
      }
      this.pageLoadFlag = true
      updateStatus(data).then(res=>{
        this.$message.warning(res.data.message)
        this.pageLoadFlag = false
        this.getDetail(this.$route.query.id)
      })
    },
    // 通过窗口确定
    cancelOk() {
      let data = {
        id:this.salesOrderId,
        audit:'通过',
        auditRemark:''
      }
      this.pageLoadFlag = true
      updateStatus(data).then(res=>{
        this.$message.warning(res.data.message)
        this.pageLoadFlag = false
        this.getDetail(this.$route.query.id)
      })
    },
    goBack() {
      window.close()
    },
    getDetail(id){
      let data = {
        id:id
      }
      this.pageLoadFlag = true
      getOrderDetail(data).then(res=>{
        this.orderDetail = res.data
      }).finally(()=>{
        this.pageLoadFlag = false
      })
    },
  
   
    gotoProcess(){
      let routeUrl = this.$router.resolve({
        path:'/channelOrder/progress',
        query:{
          id:this.$route.query.id
        }
    });
    window.open(routeUrl.href, "_blank")
    }
  }
};
